var SimplePopup = require('../simplepopup/simplePopup.js');
var dropzone = require('dropzone');
var site_url = window.location.protocol + '//' + window.location.host;

function createFileMenager(parent, filemanager) {

    if (typeof filemanager === 'undefined' || !filemanager) {
        filemanager = false;
    }

    new SimplePopup({
        'overlayClass': 'media_library_overlay_',
        'contentClass': 'media-library-view-',
        'closeBtn': '.close-popup',
        'cascade': true,
        'content': {
            url: site_url + "/admin/media/files",
        },
        'contentCss': {
            'background-color': 'transparent',
            'padding': '0',
            'border': 'none',
            'width': '69.5%',
            'top': '30px',
        },
        onAfterOverlayShow: function (el, self) {
            self.preloaderStart(self.overlayClass);
        },
        onAfterContentShow: function (el, self) {
            datatablesInit();

            // console.log("onAfterContentShow", self);
            self.preloaderEnd(self.overlayClass);

            $(el).on('click', '.file-preview', function () {
                if (parent.length > 0) {
                    //console.log(parent,$('input[name="' + parent + '"]'));
                    $('input[name="' + parent + '"]').val("App\\Component\\Cms\\Entity\\Media::" + $(this).attr('data-id'));
                    $('input[name="' + parent + '"]').attr("value", "App\\Component\\Cms\\Entity\\Media::" + $(this).attr('data-id'));

                    var img = $(this).html();
                    $('input[name="' + parent + '"]').parent().find('.tmp-img').remove();
                    $('input[name="' + parent + '"]').parent().find('.media-library-picker').append("<div class='tmp-img'>" + img + '<i class="fa fa-trash delKey" aria-hidden="true"></i>' + "</div>")

                } else if (filemanager) {
                    var data = $(this).find('img').attr('src');
                    data = data.replace(site_url, "");
                    data = data.replace('_thumbs', "");
                    console.log(data, $(this));
                    $(filemanager).val(data);
                }

                self.removeFromDOM(self);
            });

            $(el).on('click', '.filemanager-add-btn', function () {
                new SimplePopup({
                    'contentClass': 'files-upload-popup-',
                    'closeBtn': '.close-popup',
                    'content': {
                        url: site_url + "/admin/media/add",
                    },
                    'optionalData': self,
                    onAfterOverlayShow: function (el, self) {
                        self.preloaderStart(self.overlayClass);
                    },
                    onAfterContentShow: function (el, self) {
                        self.preloaderEnd(self.overlayClass);
                        $(el).find(".dropzone").dropzone({
                            //autoProcessQueue: false,
                            url: site_url + "/admin/media/upload",
                            init: function () {
                                this.on("addedfile", function (file) {
                                    console.log("Added file");
                                });
                                this.on("removedfile", function (file) {
                                    console.log("removed file");
                                });
                                this.on("success", function (file, response) {
                                    var src = $.parseHTML(file.previewElement.innerHTML);
                                    src = $(src).find('img').attr('src');
                                    $.post(site_url + "/admin/media/upload-thumbnail", {
                                        thumb_src: src,
                                        filename: response.filename
                                    });
                                });
                                this.on("sending", function (file, xhr, formData) {
                                    console.log('file > height, width', file.height, file.width);
                                    /*formData.append("file_height", file.height);
                                    formData.append("width", file.width);*/
                                });
                            }
                        });
                    },
                    onBeforeContentClose: function (el, self) {
                        var parent = self.settings.optionalData;
                        parent.html = '';
                        parent.preloaderStart();
                        var v = parent.getContent(parent);
                        setTimeout(function () {
                            $(parent.contentClass).html(parent.html);
                            setTimeout(function () {
                                datatablesInit();
                            }, 200);
                        }, 3000);
                    }
                });
            });


            $(el).on('click', '.filemanager-delete, .delete-all-media', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var val = [];
                var i = 0;
                $('.media-multi-check:checked').each(function () {
                        val[i] = $(this).val();
                        i++;
                    }
                );
                var parameters = val.join('|');



                if(parameters.length > 0){
                    var url = $(this).attr('data-href') + '/' + parameters;
                }else{
                var url = $(this).attr('data-href');
                }
                console.log(url);
                new SimplePopup({
                    'relativeElement': '.media-library-view-0',
                    'optionalData': self,
                    'contentClass': 'filemanager-confirmation',
                    'closeBtn': '.close-popup',
                    'contentHtmlId': 'filemanager-confirm',
                    'contentCss': {
                        'background': '#fff !important',
                        'padding': '60px 60px 30px 60px',
                        'border': 'none',
                        'width': '30%',
                        'top': '30px',
                    },
                    onAfterContentShow: function (el, self) {
                        $(el).on('click', '.filemanager-confirm-delete', function () {
                            console.log(url);
                            $.post(url)
                                .done(function (data) {
                                    console.log('done');
                                    self.removePopup(self);
                                })
                                .fail(function (data) {
                                    console.log('fail');
                                    self.removePopup(self);
                                });
                        });

                        $(el).on('click', '.filemanager-confirm-cancel', function () {
                            self.removePopup(self);
                        });
                    },
                    onBeforeContentClose: function (el, self) {
                        var parent = self.settings.optionalData;
                        parent.html = '';
                        parent.preloaderStart();
                        var v = parent.getContent(parent);
                        setTimeout(function () {
                            $(parent.contentClass).html(parent.html);
                            setTimeout(function () {
                                datatablesInit();
                            }, 200);
                        }, 3000);
                    }
                });
            });

            $(el).on("click", ".filemanager-add-directory-btn", function () {
                console.log(".filemanager-add-directory-btn");
                new SimplePopup({
                    'contentClass': 'files-upload-popup-',
                    'closeBtn': '.close-popup',
                    'content': {
                        url: site_url + "/admin/media/directory",
                    },
                    onAfterContentShow: function (el, self) {
                        $(el).on("click", "#create-directory button", function (e) {
                            e.preventDefault();
                            $(el).find('span.message').removeClass('success').removeClass('error').fadeOut();

                            $.post(site_url + "/admin/media/create-directory", $("#create-directory").serialize())
                                .done(function (data) {
                                    console.log('data', data);
                                    $(el).find('span.message').addClass('success').html(data.success).fadeIn();
                                })
                                .fail(function (data) {
                                    console.log('data', data);
                                    $(el).find('span.message').addClass('error').html(data.responseJSON.error).fadeIn();
                                });
                        });
                    },
                    /*onBeforeContentClose: function (el, self) {
                        var parent = self.settings.optionalData;
                        parent.html = '';

                        var v = parent.getContent(parent);
                        setTimeout(function(){
                            $(parent.contentClass).html(parent.html);
                        }, 3000);
                    }*/
                });
            });
        }
    });
}

function datatablesInit() {
    $('.dataTable').DataTable({
        dom: "<'container-fluid pl-0 pr-0'<'row media-library-row'<'col-sm-3 col-md-3 center-align'l><'col-sm-3 col-md-3 center-align'f><'col-sm-6 center-align'p>>>" +
            "<'container-fluid pl-0 pr-0'<'row'<'col-sm-12'<'lib-table-wrapper'tr>>>>" +
            "<'container-fluid pl-0 pr-0'<'row'<'col-sm-12'p>>>",
        "order": [[1, "desc"]],
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Croatian.json"
        },
        initComplete: function () {
            this.api().columns().every(function () {
                var column = this;
                if (!$(column.header()).hasClass('dt-no-filter')) {
                    var select = $('<br /><select><option value="">Odaberi</option></select>')
                        .appendTo($(column.header()))
                        .on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    column.data().unique().sort().each(function (d, j) {
                        select.append('<option value="' + d + '">' + d + '</option>')
                    });
                }
            });
        }
    });
}

$(document).ready(function () {
    $(document).on("click", ".filemanager-btn", function (e) {
        e.preventDefault();

        var parent = '';
        if ($(this).attr('data-parent')) {
            parent = $(this).attr('data-parent');
        }

        createFileMenager(parent);
    });

    /* REPLACE INPUT FIELD WITH MEDIA LIBRARY PICKER */
    setTimeout(function () {
        $('input[data-entity*="App\\\\Component\\\\Cms\\\\Entity\\\\Media"]').each(function (i, el) {
            var description = $(this).attr('placeholder');
            var field_id = $(this).attr('name');

            $(this).css('display', 'none').parent().append('<div class="media-library-picker"><button class="btn btn-dark filemanager-btn" data-parent="' + field_id + '">Odaberite datoteku&nbsp;&nbsp;&nbsp;<i class="fas fa-images"></i></button></div>');
        });
    }, 1000);

    $(document).on('click', '.cke_button_icon.cke_button__image_icon', function (e) {
        setTimeout(function () {
            $('.cke_dialog_background_cover').css('z-index', '1000');
            $('.cke_dialog').css('z-index', '1010');

            var fmPos = $(".cke_dialog_contents .cke_dialog_image_url .cke_dialog_ui_hbox_last");
            if (fmPos.has('i').length == 0) {
                fmPos.append('<i class="fas fa-folder-open filemenager"></i>');
            }

        }, 300);
    });

    $(document).on('click', '.filemenager', function (e) {
        var input = $(this).parent().parent().find('input');
        createFileMenager('', input);
    });
});